.homeMenutop {
    position: relative;
    background: #FFFFFF;
    text-align: center;
    border-bottom: 10px solid #E5E5E5;
    height: 49px;
    line-height: 39px;
    /* width: 100%; */
}
.content {
    text-decoration: none;
    /* position: absolute;
    left: 46%; */
    line-height: 34px;
    color: #1D2860;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
  
}
.icon {
    position: absolute;
    left: 10px;
    top: 12px;
    color: #595959;
    margin-left: 1%;
    cursor: pointer;
}
