@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-spinner {

    width: 80px;
    height: 80px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;

    position:absolute;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    z-index: 999;
    overflow:hidden;
    text-align: center;
  }