.review_image {

    :global(.modal-body) {
        padding: 0.25rem 0.5rem;
        max-height: 80vh;
        overflow-y: auto;
    }

    :global(.modal-footer) {
        padding: 0;
        border: 0;
        display: flex;

        :global(input) {
            flex: 1;

            &:global(:focus) {
                outline: none !important;
                // border-color: inherit;
                -webkit-box-shadow: none;
                box-shadow: none;

            }
        }

        :global(svg) {
            cursor: pointer;

            &:global(:hover) {
                color: #0d6efd;
            }
        }
    }

    :global(.listFileUpload) {
        // display: flex;
        // flex-flow: wrap;
        :global(.item) {
            // display: flex;
            // align-items: center;
            position: relative;
            width: 50%;
        
        
            justify-content: space-between;
            // margin: 0.5rem 0;
            border-bottom: 1px solid #ddd;
            padding: 0.5rem 0;

            &:global(:last-child) {
                border-bottom: none;
            }

            > :global(img) {
                width: 260px;
                height: 260px;
                object-fit: cover;
                margin-right: 20px;
            }

            :global(svg) {
                font-size: 2em;
                cursor: pointer;
                position: absolute;
                left: 10px;
                top: 15px;
                color: white;
                border: 1px solid grey;
                background-color: rgb(0, 0, 0, 0.5);
                padding: 5px 5px;

                &:global(:hover) {
                    // color: #0d6efd;
                    color: red;
                }
            }

        }
    }
}