body {
    font-family: Quicksand;
}

.formFieldLabel {
    margin-left: 10px;
    margin-right: 30px;
    width: 60px;
    vertical-align: top;
    /* position: absolute;
    top: 10px;
    left: 2% */

}

.formField {
    /* position: relative; */
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 1%;
}
.formFieldCount {
    /* padding-top: -10px; */
    width: 100%;
    margin-top: -15px;
    margin-bottom: 10px;
    margin-left: 1%;
}

.count {
    /* position: absolute;
    left: 92%;
    top: 70px; */
    font-weight: 100;
    font-size: 12px;
}

.formFieldInputCategory {
    font-family: Quicksand;
    width: 20%;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    cursor: pointer;
}

.formFieldInputCategory option {
    cursor: pointer;
}

.formFieldInputDescription {
    width: 85%;
    height: 97px;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    resize: none;
    padding: 5px;
    padding-right: 90px;
}

.attachmentBtn {
    font-family: Quicksand;
    font-weight: 500;
    border: 1px solid #E7E8F3;
    background: #E7E8F3;
    border-radius: 36px;
    color: #494D71;
    /* width: 100% ; */
    /* height: 26px; */
    line-height: 26px;
    cursor: pointer;

}

.attachment {
    /* margin-left: 1%; */
    margin-left: 5px;
    width: 126px;
    text-align: center;
    /* position: absolute;
    left: 0px; */
}

.file {
    /* margin-left: 100px; */
    /* margin-top: -10px; */
    margin-left: -30px ;
}

.attack {
    /* display: flex; */
    margin-top: 20px;
    position: relative;
    padding-bottom: 100px;
    /* z-index: -1; */
}

.item {
    padding-right: 20px;
    padding-left: 20px;
}

.iconRemove {
    color: #A3A5B4;
    font-size: 1.5em;
    /* position: absolute;
    top: 0px; */
}

.listImg li {
    display: inline-block;
    border-right: 1px solid #BDC2D2;
    margin-top: 10px;
}

.listImg li:last-child {
    border-width: 0px;
}

.listImg li a {
    color: #2F80ED;
}


.create {
    position: fixed;
    bottom: 0px;
    text-align: center;
    /* left: 45%; */
    border-top: 2px solid #E5E5E5;
    width: 100%;
    height: 61px;
    line-height: 61px;
    /* z-index: 1; */
    background: #FFFFFF;

}

.btnCreate {
    /* margin-left: 43%; */
    color: #FFFFFF;
    background: #4276FE;
    border-radius: 20px;
    border-color: white;
    border: 1px solid #4276FE;
    line-height: 22px;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 500;
    width: 153px;
    height: 31px;
    cursor: pointer;
}

.image {
    /* position: relative; */
    margin-right: 3px;
}

.removeIcon {
    font-size: 2em;
}

.removeBtn {
    margin-left: 5px;
    text-align: center;
    border: 1px solid grey;
    border-radius: 20px;
    border-color: white;
    color: white;
    width: 20px;
    height: 20px;
    /* padding-right: -20px; */
    background-color: #A3A5B4;
}

.show_image img {
    width: 100%;
    height: auto;
}
