.thread_details{
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f1f1f1;
    :global(.react-simple-image-viewer__close){
        z-index: 1000;
    }
    :global(.breakcum) {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding: 0.25rem 0.25rem;
        background-color: white;
        
        :global(.category-tag) {
            background-color: #d8d8d8;
            border-radius: 1rem;
            height: 28px;
            // width: 120px;
            padding-left: 1rem;
            padding-right: 1rem;
            text-align: center;
            line-height: 28px;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }

    :global(.content){
        flex: 1 1 auto;
        overflow-y: auto;
        
        > :global(.question){
            margin: 0.5rem 0;
            background-color: white;
            padding: 0.25rem 0.5rem;
            > :global(.title){
                font-weight: bold;
                margin-bottom: 0.5rem;
                color: #1D2860;
                word-wrap: break-word;
                
            }

            > :global(.thumb){
                > img{
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                }
            }
        }

        > :global(.comments){
            // background-color: #d5d5d5;
            padding: 0.5rem 0.5rem;
            border-radius: 0.5rem;
            :global(.date){
                color: #333;
                text-align: center;
            }

            :global(.item){
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;

                > :global(.msg){
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    :global(.text){
                        background-color: white;
                        color: #494D71;
                        border-radius: 0.5rem;
                        padding: 0.25rem 0.5rem;
                        margin-right: 0.5rem;

                        &:global(.admin){
                            background-color: #DFE0EE
                        }
                    }

                    > :global(.time){
                        display: flex;
                        align-items: center;
                        color: #777;
                        > :global(svg){
                            margin-right: 0.1rem;
                            font-size: 0.75rem;
                        }

                        > :global(.checked){
                            color: #0d6efd;
                        }
                    }
                }

                :global(.thumb){
                    > img{
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                    }
                }
            }
        }
    
    }

    
    > :global(.action){
        margin: 0.5rem 0;
        > :global(.comment-form){
            display: flex;
            align-items: center;
            background-color: #FFFFFF;
            padding: 0.5rem 0.25rem;

            :global(.btncomment) {
                border: none;
                border-radius: 1rem;

            }
            :global(.btncomment) {
                border: 1px solid #F1F1F1;
                cursor: pointer;
            }

            :global(.input-group) {
                flex: 1;
                
    
                > :global(.input-text){
                    border-radius: 1rem 0 0 1rem;
                    background-color: #E5E5E5;
                    border: none;
                    &:global(:focus) {
                        outline: none !important;
                        // border-color: inherit;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                       
                    }
                }

                > :global(.input-group-append){
                    background-color: #E5E5E5;
                    border-radius: 0 1rem 1rem 0;
                    > :global(span){
                        background-color: transparent;
                        color: #555;
                        border: none;
                        font-size: 0.75rem;
                        line-height: 2;
                    }
                }
                
            }

            :global(.btnattach) {
                margin: 0 1rem;
                cursor: pointer;
            }
        }

        >:global(.ratingForm){
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            > :global(.rateText){
                text-align: center;
                font-weight: bold;
                color: #1D2860;
                margin-bottom: 1rem;
            }

            > :global(div:last-child){
                display: flex !important;
                align-items: center;
                justify-content: center;
                background-color: white;
            }
        }

        >:global(.confirm){
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;

            > :global(.text){
                font-weight: bold;
                color: #1D2860;
                margin-bottom: 0.5rem;
            }
            
            > :global(.buttons){
                > :global(.btn){
                    border: none;
                    margin: 0 0.5rem;
                    border-radius: 1rem;
                    min-width: 5rem;
                    &:global(:focus) {
                        outline: none !important;
                        // border-color: inherit;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                       
                    }
                    &:global(.deny){
                        background-color: #858691;
                    }
                }
            }
        }
        
    }
}