div {
    font-family: 'Quicksand';
}

.homeMenutop {
    position: relative;
    background: #FFFFFF;
    text-align: center;
    border-bottom: 10px solid #E5E5E5;
    /* padding-bottom: 10px; */
    height: 49px;
    line-height: 39px;
    /* padding-left: 40px; */
    /* padding-right: 50px; */

}

.content {
    text-decoration: none;
    /* position: absolute;
    left: 43%; */
    /* overflow-x: auto; */
    line-height: 34px;
    color: #1D2860;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;

}

.icon {
    color: #595959;
    position: absolute;
    left: 10px;
    top: 12px;

}

.iconDown {
    position: absolute;
    right: -30px;
    top: 0px;
}

.btnShow {
    position: fixed;
    right: 20px;
    top: 50%;
    cursor: pointer;
}

.ans {
    /* display: none; */
    margin-top: 10px;
    margin-left: 10px;
    font-size: 14px;
    padding-right: 5px;
}

.yourThread {
    border-bottom: 5px solid #E5E5E5;
    padding-bottom: 30px;
    height: 50vh;
    position: relative;
}

.titleYourThread {
    color: #1D2860;
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 1%;
    text-transform: uppercase;
}

.listThreads {
    height: 90%;
    overflow: auto;
}

.itemYourThread {
    display: flex;
    margin-left: 2%;
    margin-top: 1%;
}

.categoryYourThread {
    border: 1px solid #E7E8F3;
    padding: 3px 20px;
    background: #E7E8F3;
    width: 150px;
    text-align: center;
    border-radius: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    position: relative;
}

.detailYourThread {
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 30px;
    padding-left: 20px;
    color: #2F80ED;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
}

.detailYourThread Link {
    color: #2F80ED;
    font-size: 16px;
}

/* .detailYourThread {
    margin-top: 4px;
    padding-right: 30px;
    padding-left: 20px;
    width: 100%;
    position: relative;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #2F80ED;
    font-size: 16px;
    cursor: pointer;
}
.text Link {
    color: #2F80ED;
    font-size: 16px;
} */
.noti{
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: #C21A1A;;
    position: absolute;
    right: 5px;
    top: 2px;

    
}
.faqList {
    overflow: auto;
}

.titleFaq {
    color: #1D2860;
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 1%;
    text-transform: uppercase;
}

.itemFaq {
    margin-left: 2%;
    margin-top: 20px;
    margin-bottom: 20px;

}
.faqQues {
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 10px;
    
}

/* .showmore {
    font-weight: bold;
} */
.question {
    font-size: 18px;
    margin-right: 40px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;

}

.faqDetail {
    margin-right: 10px;
    padding-bottom: 20px;
}

.categoryFaq {
    color: #595959;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 10px;
}

.categoryFaq:last-child {
    border-width: 0px;
}

.questionFaq {
    color: #595959;
    margin-top: 5px;
    /* position: relative; */
    border-bottom: 1px solid #DFDFDF;
    margin-top: 10px;
    padding-bottom: 20px;
}

.btnCreate {
    font-family: Quicksand;
    font-weight: 500;
    color: #FFFFFF;
    background: #4276FE;
    border-radius: 20px;
    border-color: white;
    border: 1px solid #4276FE;
    line-height: 22px;
    width: 83px;
    height: 31px;
    font-size: 14px;
    position: absolute;
    right: 1%;
    top: 4px;
    cursor: pointer;
}